import { BillingInformationDto } from 'projects/helper-client/src/api/gen';
import { BillingAddress } from './billing-address';

export class BillingInformation {
  name?: string | null;
  eMail?: string | null;
  phoneNumber?: string | null;
  sendTextNotificationIfPaymentFails?: boolean;
  address?: BillingAddress;

  static fromDto(dto: BillingInformationDto): BillingInformation {
    return {
      name: dto.name,
      eMail: dto.eMail,
      phoneNumber: dto.phoneNumber,
      sendTextNotificationIfPaymentFails:
        dto.sendTextNotificationIfPaymentFails,
      address: BillingAddress.fromDto(dto.address),
    } as BillingInformation;
  }
}
