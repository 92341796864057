import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../../services/api/subscription.service';
import { first } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionExpiredDialogComponent } from '../../shared/dialogs/subscription-expired-dialog/subscription-expired-dialog.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubscriptionService],
})
export class DashboardComponent implements OnInit {
  constructor(
    private readonly subscriptionService: SubscriptionService,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.subscriptionService
      .getLatestSubscription()
      .pipe(first())
      .subscribe((x) => {
        if (x.endedAt && new Date(x.endedAt) <= new Date()) {
          this.dialog.open(SubscriptionExpiredDialogComponent, {
            disableClose: true,
            data: { expiredAt: new Date(x.endedAt) },
          });
        }
      });
  }
}
