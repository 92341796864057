import { FoundTextTranslationDto } from '../../../api/gen';

export class FoundTextTranslation {
  id?: string;
  text?: string | null;
  translationLocale?: string | null;

  static fromDto(dto: FoundTextTranslationDto): FoundTextTranslation {
    return {
      id: dto.id,
      text: dto.text,
      translationLocale: dto.translationLocale,
    } as FoundTextTranslation;
  }
}
