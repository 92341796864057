<h2 mat-dialog-title i18n class="ns">Standort</h2>
<mat-dialog-content>
  <iframe
    [src]="mapUrl | safe: 'resourceUrl'"
    width="100%"
    height="95%"
    style="border: none"
    loading="lazy"
  ></iframe>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="warn" mat-dialog-close i18n>Schließen</button>
</mat-dialog-actions>
