import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  MatListItem,
  MatListItemLine,
  MatListItemTitle,
} from '@angular/material/list';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-inquiry-info-item',
  standalone: true,
  imports: [MatListItem, MatListItemLine, MatListItemTitle, MatButton],
  templateUrl: './inquiry-info-item.component.html',
  styleUrl: './inquiry-info-item.component.scss',
})
export class InquiryInfoItemComponent implements OnInit {
  @Input() label: string;
  @Input() value: string;
  @Input() actionName: string = $localize`Bearbeiten`;
  @Input() actionEnabled: boolean;
  @Output() actionClicked = new EventEmitter();

  ngOnInit(): void {
    if (!this.value) {
      this.value = $localize`Unbekannt`;
    }
  }

  actionClick() {
    this.actionClicked.emit();
  }
}
