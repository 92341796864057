<div class="multi-select-container">
  <div
    class="selection"
    *ngFor="let duration of durations"
    (click)="changeSelection(duration.value)"
    [class.selected]="selection === duration.value"
    [class.disabled]="!enabled"
  >
    {{ duration.label }}
  </div>
</div>
