import { ReferrerDto } from 'projects/helper-client/src/api/gen';

export class Referrer {
  externalIdentifier?: string | null;
  usedReferralCode?: string | null;

  static fromDto(dto: ReferrerDto): Referrer {
    return {
      externalIdentifier: dto.externalIdentifier,
      usedReferralCode: dto.usedReferralCode,
    } as Referrer;
  }
}
