<h2 class="dialog-title ns" mat-dialog-title i18n>Einstellungen</h2>
<mat-dialog-content>
  <div class="content">
    <div class="options">
      <div class="setting">
        <app-device-select
          #videoSelect
          [selectedId]="selectedVideo"
          iconName="videocam"
          (settingsChanged)="onSettingsChanged($event)"
          [devices]="devices.camOptions"
          [kind]="'videoinput'"
          i18n-label
          label="Video Eingang"
          key="videoInputId"
        ></app-device-select>
        <app-camera-settings-preview class="preview"></app-camera-settings-preview>
      </div>
      <div class="setting">
        <app-device-select
          (settingsChanged)="onSettingsChanged($event)"
          [devices]="devices.micOptions"
          [kind]="'audioinput'"
          [selectedId]="selectedAudioIn"
          iconName="mic"
          i18n-label
          label="Audio Eingang"
          key="audioInputId"
        ></app-device-select>
        <app-mic-settings-preview class="preview"></app-mic-settings-preview>
      </div>
      <div class="setting">
        <app-device-select
          [kind]="'audiooutput'"
          [selectedId]="selectedAudioOut"
          iconName="volume_up"
          i18n-label
          label="Audio Ausgang"
          [disabled]="!isOutputSelectionPossible"
          [devices]="devices.speakerOptions"
          (settingsChanged)="onSettingsChanged($event)"
          key="audioOutputId"
        ></app-device-select>
        <app-play-test-sound class="preview"></app-play-test-sound>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="warn">
    <mat-icon>close</mat-icon>
    <span i18n>Abbrechen</span>
  </button>
</mat-dialog-actions>
