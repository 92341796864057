import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForOf } from '@angular/common';

@Component({
  selector: 'app-duration-input',
  standalone: true,
  imports: [NgForOf],
  templateUrl: './duration-input.component.html',
  styleUrl: './multi-select-input.component.scss',
})
export class DurationInputComponent {
  @Input()
  public selection?: DurationSelection;

  @Input()
  public enabled = true;

  @Output()
  public selectionChange = new EventEmitter<DurationSelection>();

  public durations: DurationSelectionOption[] = [
    { value: DurationSelection.ZeroToOneHour, label: '< 1h' },
    { value: DurationSelection.OneHourToTwoHours, label: '1 - 2h' },
    { value: DurationSelection.TwoHoursToFiveHours, label: '2 - 5h' },
    { value: DurationSelection.MoreThanFiveHours, label: '5h +' },
  ];

  protected changeSelection(newSelection?: DurationSelection) {
    if (!this.enabled) return;
    this.selectionChange.emit(
      newSelection === this.selection ? null : newSelection,
    );
  }
}

export interface DurationSelectionOption {
  value: DurationSelection;
  label: string;
}

export enum DurationSelection {
  ZeroToOneHour,
  OneHourToTwoHours,
  TwoHoursToFiveHours,
  MoreThanFiveHours,
}
