/**
 * Agent Client
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SavedDuration = 'ZeroToOneHour' | 'OneHourToTwoHours' | 'TwoHoursToFiveHours' | 'MoreThanFiveHours';

export const SavedDuration = {
    ZeroToOneHour: 'ZeroToOneHour' as SavedDuration,
    OneHourToTwoHours: 'OneHourToTwoHours' as SavedDuration,
    TwoHoursToFiveHours: 'TwoHoursToFiveHours' as SavedDuration,
    MoreThanFiveHours: 'MoreThanFiveHours' as SavedDuration
};

