<div class="container">
  <mat-icon>{{iconName}}</mat-icon>
  <mat-form-field appearance="fill" subscriptSizing="dynamic">
    <mat-label>{{ label }}</mat-label>
    <mat-select
      [disabled]="disabled"
      (valueChange)="onSettingsChanged($event)"
      [(value)]="selectedId"
    >
      <mat-option *ngFor="let device of devices" [value]="device.deviceId">{{
          device.label
        }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>


