<div class="container">
  <app-audio-visualization *ngIf="(isMuted$ | async) === false" class="preview"></app-audio-visualization>
  <div *ngIf="(isMuted$ | async)">
    <button
      mat-button
      color="primary"
      (click)="turnOnMic()"
      i18n
    >
      Einschalten
    </button>
  </div>
</div>
