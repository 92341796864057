import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { FoundText } from '../../../model/attachment/found-text';
import { AttachmentCvService } from '../../../services/api/attachment-cv.service';
import { supportedLocales } from 'aidar-live-chat';
import { FoundTextTranslation } from '../../../model/attachment/found-text-translation';

@Component({
  selector: 'app-ocr-result-dialog',
  templateUrl: './ocr-result-dialog.component.html',
  styleUrls: ['./ocr-result-dialog.component.scss'],
  providers: [AttachmentCvService],
})
export class OcrResultDialogComponent {
  displayedColumns: string[] = ['text', 'copy', 'translate'];
  dataSource = this.data.foundTexts.map((t) => FoundTextInfo.fromFoundText(t));
  protected selectedTargetLocale = 'DE';

  constructor(
    private readonly attachmentCvService: AttachmentCvService,
    private readonly clipboard: Clipboard,
    private readonly snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<OcrResultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OcrResultDialogData,
  ) {}

  copyElementToClipboard(element: FoundText) {
    this.copyToClipboard(element.text);
  }

  copyEverythingToClipboard() {
    this.copyToClipboard(this.getCompleteOcrText());
  }

  private getCompleteOcrText(): string {
    const finalText = [];

    for (const element of this.data.foundTexts) {
      finalText.push(element.text);
      finalText.push('\n');
    }

    return finalText.join('');
  }

  private copyToClipboard(text: string): void {
    const pending = this.clipboard.beginCopy(text);

    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else if (!result) {
        this.snackBar.open(
          $localize`Fehler beim Kopieren in die Zwischenablage!`,
          null,
          { duration: 5000 },
        );
      } else {
        this.snackBar.open($localize`In die Zwischenablage kopiert!`, null, {
          duration: 5000,
        });
        pending.destroy();
      }
    };
    attempt();
  }

  translateText(element: FoundTextInfo) {
    const locale = this.selectedTargetLocale;
    this.attachmentCvService
      .translateFoundText(this.data.attachmentId, element.id, locale)
      .subscribe((res) => {
        const idx = res.translations.findIndex(
          (x) => x.translationLocale === locale,
        );
        if (idx >= 0) {
          const translation = res.translations[idx];
          element.text = translation.text;
          element.translated = true;
        }
      });
  }

  showOriginalText(element: FoundTextInfo) {
    element.text = element.originalText;
    element.translated = false;
  }

  protected readonly supportedLocales = supportedLocales;
}

export interface OcrResultDialogData {
  attachmentId: string;
  foundTexts: FoundText[];
}

class FoundTextInfo {
  id: string;
  text: string;
  originalText: string;
  translations?: FoundTextTranslation[] | null;
  translated: boolean;

  static fromFoundText(foundText: FoundText) {
    return {
      id: foundText.id,
      text: foundText.text,
      originalText: foundText.text,
      translations: foundText.translations,
      translated: false,
    } as FoundTextInfo;
  }
}
