import { Component, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';

@Component({
  selector: 'app-subscription-expired-dialog',
  standalone: true,
  imports: [DatePipe, MatDialogTitle, MatDialogContent],
  templateUrl: './subscription-expired-dialog.component.html',
  styleUrl: './subscription-expired-dialog.component.scss',
})
export class SubscriptionExpiredDialogComponent {
  protected expiredAt: Date;

  constructor(@Inject(MAT_DIALOG_DATA) data: { expiredAt: Date }) {
    this.expiredAt = data.expiredAt;
  }
}
