import { Component, OnDestroy } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AudioLevelService } from '../../services/audio-level-service';

@Component({
  selector: 'app-audio-visualization',
  templateUrl: './audio-visualization.component.html',
  styleUrls: ['./audio-visualization.component.scss'],
})
export class AudioVisualizationComponent implements OnDestroy {
  protected audioLevel: Observable<number>;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(readonly audioLevelService: AudioLevelService) {
    this.audioLevel = audioLevelService.localAudioVolume$.pipe(
      takeUntil(this.unsubscribe$),
      map((x) => {
        return x * 10;
      }),
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
