import { SavedDuration, SavedDurationService as Api } from '../../../api/gen';
import { ArgumentOutOfRangeError, Observable } from 'rxjs';
import { DurationSelection } from '../../shared/multi-select-input/duration-input.component';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class SavedDurationService {
  constructor(private readonly durationService: Api) {}

  storeSavedDuration(
    inquiryId: string,
    newDuration?: DurationSelection,
  ): Observable<unknown> {
    return this.durationService
      .inquiryIdSavedDurationPost(inquiryId, {
        newSavedDuration: this.cast(newDuration),
      })
      .pipe(map(() => true));
  }

  getSavedDuration(inquiryId: string): Observable<DurationSelection> {
    return this.durationService
      .inquiryIdSavedDurationGet(inquiryId)
      .pipe(map((duration) => this.castReverse(duration)));
  }

  private castReverse(duration?: SavedDuration): DurationSelection | null {
    if (!duration) return null;
    switch (duration) {
      case SavedDuration.ZeroToOneHour:
        return DurationSelection.ZeroToOneHour;
      case SavedDuration.OneHourToTwoHours:
        return DurationSelection.OneHourToTwoHours;
      case SavedDuration.TwoHoursToFiveHours:
        return DurationSelection.TwoHoursToFiveHours;
      case SavedDuration.MoreThanFiveHours:
        return DurationSelection.MoreThanFiveHours;
      default:
        throw ArgumentOutOfRangeError;
    }
  }

  private cast(duration?: DurationSelection): SavedDuration | null {
    if (duration == null) return null; // otherwise enum problems
    switch (duration) {
      case DurationSelection.ZeroToOneHour:
        return SavedDuration.ZeroToOneHour;
      case DurationSelection.OneHourToTwoHours:
        return SavedDuration.OneHourToTwoHours;
      case DurationSelection.TwoHoursToFiveHours:
        return SavedDuration.TwoHoursToFiveHours;
      case DurationSelection.MoreThanFiveHours:
        return SavedDuration.MoreThanFiveHours;
      default:
        throw ArgumentOutOfRangeError;
    }
  }
}
