import { Component, Inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { SharedModule } from '../../../shared/shared.module';

@Component({
  selector: 'app-view-location-dialog',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    SharedModule,
  ],
  templateUrl: './view-location-dialog.component.html',
  styleUrl: './view-location-dialog.component.scss',
})
export class ViewLocationDialogComponent {
  mapUrl: string;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { latitude: number; longitude: number },
  ) {
    const { latitude, longitude } = this.data;
    this.mapUrl = `https://www.openstreetmap.org/export/embed.html?bbox=${longitude - 0.01},${latitude - 0.01},${longitude + 0.01},${latitude + 0.01}&layer=mapnik&marker=${latitude},${longitude}`;
  }
}
