import { LatLngDto } from '../../../api/gen';

export class LatLng {
  latitude?: number;
  longitude?: number;

  static fromDto(dto: LatLngDto): LatLng {
    return { latitude: dto.latitude, longitude: dto.longitude } as LatLng;
  }
}
