<div class="container">
  <div class="info-item-container">
    <span class="info-item-value ns">{{ value }}</span>
    <span class="info-item-label ns">{{ label }}</span>
  </div>
  <span class="filler"></span>
  @if (actionEnabled) {
    <button mat-button color="primary" (click)="actionClick()">
      {{ actionName }}
    </button>
  }
</div>
