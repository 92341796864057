<div>
  <app-heading-with-icon-and-action
    i18n-title
    title="Einsparungen"
    icon="savings"
    i18n-actionTitle
    actionTitle="Bearbeiten"
    [actionTitle]="allowSelection ? textFinished : textEdit"
    [actionIcon]="allowSelection ? 'done' : 'edit'"
    (actionTriggered)="toggleEdit()"
  >
  </app-heading-with-icon-and-action>
  <div class="content">
    <div class="outer-container">
      <div class="container">
        <h3 i18n>Zeit</h3>
        <app-duration-input
          *ngIf="!durationLoading"
          [selection]="selectedDuration"
          (selectionChange)="durationSelectionChanged($event)"
          [enabled]="allowSelection"
        ></app-duration-input>
      </div>
      <div class="container">
        <h3 i18n>Distanz</h3>
        <app-distance-input
          *ngIf="!distanceLoading"
          [selection]="selectedDistance"
          (selectionChange)="distanceSelectionChanged($event)"
          [enabled]="allowSelection"
        ></app-distance-input>
      </div>
    </div>

    <mat-progress-spinner
      *ngIf="distanceLoading || durationLoading"
      mode="indeterminate"
      diameter="25"
    ></mat-progress-spinner>
  </div>
</div>
