<div class="container">
  <div [hidden]="!cameraTurnedOn" class="preview" #preview></div>
  <span *ngIf="cameraTurnedOn && problemText">{{ problemText }}</span>
  <div *ngIf="!cameraTurnedOn">
    <button
      mat-button
      color="primary"
      [disabled]="toggleCameraButtonDisabled"
      (click)="turnOnCamera()"
      i18n
    >
      Einschalten
    </button>
  </div>
</div>
