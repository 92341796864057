import {
  AfterViewInit,
  Component,
  ElementRef,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DiagnosticsService } from '../../services/diagnostics/diagnostics.service';
import { distinctUntilChanged, map, Subject, takeUntil } from 'rxjs';
import { AidarPermissionStatus } from '../../services/permission.service';
import { CallCtrlService } from '../../services/call-ctrl.service';
import { MediaTrackService } from '../../services/track-ctrl/media-track.service';
import { AidarVideoTrack } from '../../services/track-ctrl/aidar-media-track';

@Component({
  selector: 'app-camera-settings-preview',
  templateUrl: './camera-settings-preview.component.html',
  styleUrls: ['./camera-settings-preview.component.scss'],
})
export class CameraSettingsPreviewComponent implements AfterViewInit {
  @ViewChild('preview') previewElement: ElementRef;

  private readonly unsubscribe$ = new Subject();

  protected problemText = '';

  protected deviceAvailable = true;
  protected permissionGiven = true;
  protected cameraTurnedOn = true;

  protected toggleCameraButtonDisabled = false;

  constructor(
    private readonly callCtrlService: CallCtrlService,
    private readonly diagnosticsService: DiagnosticsService,
    private readonly mediaTrackService: MediaTrackService,
    private readonly renderer: Renderer2,
  ) {
    this.diagnosticsService.diagnosticsChanged$
      .pipe(
        takeUntil(this.unsubscribe$),
        map((x) => {
          return {
            localCameraDevicesAvailable: x.localCameraDevicesAvailable,
            localCameraPermissionStatus: x.localCameraPermissionStatus,
            localCameraTurnedOn: x.localCameraTurnedOn,
          };
        }),
        distinctUntilChanged((previous, current) => {
          return (
            previous.localCameraDevicesAvailable ===
              current.localCameraDevicesAvailable &&
            previous.localCameraTurnedOn === current.localCameraTurnedOn &&
            previous.localCameraPermissionStatus ===
              current.localCameraPermissionStatus
          );
        }),
      )
      .subscribe((x) => {
        this.deviceAvailable = x.localCameraDevicesAvailable;
        this.permissionGiven = AidarPermissionStatus.isGiven(
          x.localCameraPermissionStatus,
        );
        this.cameraTurnedOn = x.localCameraTurnedOn;
        this.evaluateProblemText();
      });
  }

  ngAfterViewInit(): void {
    this.mediaTrackService.videoTrackUpdated$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((x) => this.handleNewVideoTrack(x));

    this.handleNewVideoTrack(this.mediaTrackService.getVideoTrack());
  }

  private handleNewVideoTrack(track?: AidarVideoTrack) {
    const videoElement = track?.attach();
    if (videoElement) {
      this.attachTrack(videoElement);
    }
    this.evaluateProblemText();
  }

  private evaluateProblemText(): void {
    if (!this.deviceAvailable)
      this.problemText = $localize`Keine Kameras angeschlossen`;
    else if (!this.permissionGiven)
      this.problemText = $localize`Kamera-Berechtigung nicht erteilt`;
    else if (!this.cameraTurnedOn)
      this.problemText = $localize`Kamera deaktivert`;
    else {
      this.problemText = '';
    }
  }

  private attachTrack(videoElement: HTMLMediaElement): void {
    videoElement.style.transform = 'scale(-1,1)';
    videoElement.style.objectFit = 'cover';
    this.renderer.setStyle(videoElement, 'height', '100%');
    this.renderer.appendChild(this.previewElement.nativeElement, videoElement);
  }

  protected turnOnCamera() {
    this.toggleCameraButtonDisabled = true;
    this.callCtrlService.toggleCamState().then((x) => {
      this.toggleCameraButtonDisabled = false;
    });
  }
}
