import { SubscriptionDto } from 'projects/helper-client/src/api/gen';
import { Referrer } from './referrer';
import { FeatureConfiguration } from './feature-configuration';
import { BillingInformation } from './billing-information';
import { PaymentProvider } from './payment-provider';
import { PaymentInterval } from './payment-interval';
import { SubscriptionType } from './subscription-type';

export class Subscription {
  id?: string;
  externalIdentifier?: string | null;
  paymentProvider?: PaymentProvider;
  paymentInterval?: PaymentInterval;
  subscriptionType?: SubscriptionType;
  startedAt?: string | null;
  endedAt?: string | null;
  cancelledAt?: string | null;
  featureConfiguration?: FeatureConfiguration;
  billingInformation?: BillingInformation;
  referrer?: Referrer;

  static fromDto(dto: SubscriptionDto): Subscription {
    return {
      id: dto.id,
      externalIdentifier: dto.externalIdentifier,
      paymentProvider: dto.paymentProvider,
      paymentInterval: dto.paymentInterval,
      subscriptionType: dto.subscriptionType,
      startedAt: dto.startedAt,
      endedAt: dto.endedAt,
      cancelledAt: dto.cancelledAt,
      featureConfiguration: dto.featureConfiguration
        ? FeatureConfiguration.fromDto(dto.featureConfiguration)
        : null,
      billingInformation: dto.billingInformation
        ? BillingInformation.fromDto(dto.billingInformation)
        : null,
      referrer: dto.referrer ? Referrer.fromDto(dto.referrer) : null,
    } as Subscription;
  }
}
