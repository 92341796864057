import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Distance } from '../../../shared/multi-select-input/distance-input.component';
import { DistanceService } from '../../../services/api/distance.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DurationSelection } from '../../../shared/multi-select-input/duration-input.component';
import { SavedDurationService } from '../../../services/api/saved-duration.service';

@Component({
  selector: 'app-inquiry-details-savings',
  templateUrl: './inquiry-details-savings.component.html',
  styleUrls: ['./inquiry-details-savings.component.scss'],
  providers: [DistanceService, SavedDurationService],
})
export class InquiryDetailsSavingsComponent implements OnChanges {
  @Input() inquiryId: string;
  protected selectedDistance: Distance;
  protected selectedDuration: DurationSelection;
  protected allowSelection = false;
  protected distanceLoading = true;
  protected durationLoading = true;

  protected textFinished: string = $localize`Fertig`;
  protected textEdit: string = $localize`Bearbeiten`;

  constructor(
    private readonly distanceRepo: DistanceService,
    private readonly durationRepo: SavedDurationService,
    private readonly snackBarService: MatSnackBar,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inquiryId'] && this.inquiryId) {
      this.distanceLoading = true;
      this.durationLoading = true;
      this.distanceRepo.getDistance(this.inquiryId).subscribe((x) => {
        this.selectedDistance = x;
        this.distanceLoading = false;
        this.cdr.markForCheck();
      });
      this.durationRepo.getSavedDuration(this.inquiryId).subscribe((x) => {
        this.selectedDuration = x;
        this.durationLoading = false;
        this.cdr.markForCheck();
      });
    }
  }

  public toggleEdit() {
    this.allowSelection = !this.allowSelection;
  }

  distanceSelectionChanged($event: Distance) {
    this.selectedDistance = $event;
    this.distanceRepo
      .setNewDistance(this.inquiryId, this.selectedDistance)
      .subscribe({
        error: () => {
          this.savingFailedSnackBar();
        },
      });
  }

  durationSelectionChanged($event: DurationSelection) {
    this.selectedDuration = $event;
    this.durationRepo
      .storeSavedDuration(this.inquiryId, this.selectedDuration)
      .subscribe({
        error: () => {
          this.savingFailedSnackBar();
        },
      });
  }

  private savingFailedSnackBar() {
    this.snackBarService.open(
      $localize`Speichern fehlgeschlagen`,
      $localize`Ok`,
      { duration: 2_500 },
    );
  }
}
