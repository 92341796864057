<h1 mat-dialog-title i18n>Lizenzierung abgelaufen</h1>
<div mat-dialog-content>
  <p>
    <span i18n>
      Ihr Unternehmen verfügt seit
      <b>{{ expiredAt | date: "EEEEEE, dd.MM.yyyy" }}</b> über keine gültige
      Lizenzierung mehr.<br />
      Bitte kontaktieren Sie Ihren Administrator.
    </span>
  </p>
  <p>
    <span i18n
      ><b>Achtung:</b> Ihre Daten und Ihre Zugänge werden bald gelöscht.</span
    >
  </p>
</div>
