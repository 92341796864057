<h2 mat-dialog-title i18n>Anruf beendet</h2>
<mat-dialog-content>
  <div *ngIf="inquiryId">
    <div class="savings-container">
      <app-heading-with-icon-and-action
        class="heading"
        i18n-title
        title="Einsparungen"
        icon="savings"
      >
      </app-heading-with-icon-and-action>
      <mat-button-toggle-group class="selection-toggle-group">
        <mat-button-toggle (change)="onDurationChanged($event)" i18n
          >Zeit</mat-button-toggle
        >
        <mat-button-toggle
          (change)="onDurationAndDistanceChanged($event)"
          i18n
          [checked]="displayDistance$ | async"
          >Zeit und Anfahrt</mat-button-toggle
        >
      </mat-button-toggle-group>
      <div class="selection-inputs-container">
        <app-duration-input
          *ngIf="!durationLoading"
          [selection]="selectedDuration"
          (selectionChange)="durationSelectionChanged($event)"
          [enabled]="true"
        ></app-duration-input>
        @if (displayDistance$ | async) {
          <app-distance-input
            *ngIf="!distanceLoading"
            [selection]="selectedDistance"
            (selectionChange)="distanceSelectionChanged($event)"
            [enabled]="true"
          ></app-distance-input>
        }
      </div>

      <mat-progress-spinner
        *ngIf="distanceLoading"
        mode="indeterminate"
        diameter="25"
      ></mat-progress-spinner>
      <div class="info-banner">
        <div class="icon">
          <mat-icon>info</mat-icon>
        </div>
        <div class="text ns" i18n>
          Diese Daten werden später verwendet, um individuelle Statistiken zu
          deiner blankmile Verwendung zu zeigen. <br />
          Außerdem helfen Sie uns, das Benutzererlebnis von blankmile stetig zu
          verbessern.
        </div>
      </div>
    </div>
    <div class="report-outer-container">
      <app-heading-with-icon-and-action
        class="heading"
        i18n-title
        title="Report"
        icon="attach_file"
      >
      </app-heading-with-icon-and-action>
      <div class="report-container">
        <button
          [disabled]="downloadingPdf$ | async"
          mat-flat-button
          color="accent"
          i18n
          (click)="onDownloadInquirySummary()"
        >
          PDF herunterladen
        </button>
        <button
          [disabled]="sendingPdf$ | async"
          mat-flat-button
          color="accent"
          i18n
          (click)="onSendInquirySummaryToEmployeeMail()"
          *ngIf="employeeMail$ | async"
        >
          PDF an E-Mail senden
        </button>
      </div>
      <div
        class="report-mail-address"
        *ngIf="employeeMail$ | async as mailAddress"
      >
        <div class="report-mail-address-text ns" i18n>
          E-Mail wird an {{ mailAddress }} gesendet
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    cdkFocusInitial
    class="close-button"
    mat-raised-button
    color="primary"
    mat-dialog-close
    i18n
  >
    Zur Übersicht
  </button>
</mat-dialog-actions>
