import { Component, OnDestroy } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { filter, from, fromEvent, Subject, take, takeUntil } from 'rxjs';
import {
  DeviceService,
  SettingUpdateType,
  SinkedHTMLMediaElement,
} from '../../services/device.service';

@Component({
  selector: 'app-play-test-sound',
  templateUrl: './play-test-sound.component.html',
  styleUrl: './play-test-sound.component.scss',
})
export class PlayTestSoundComponent implements OnDestroy {
  private readonly testSound: HTMLAudioElement;
  protected isPlaying = false;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(private readonly deviceService: DeviceService) {
    this.testSound = new Audio(environment.testSoundFilePath);
    if (this.deviceService.outputSelectionPossible) {
      this.deviceService.settingsChanged$
        .pipe(
          filter((x) => x.updateType === SettingUpdateType.AudioOutput),
          takeUntil(this.unsubscribe$),
        )
        .subscribe(() => {
          this.changeAudioOutput();
        });
      this.changeAudioOutput();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggleTestSound(): void {
    if (!this.isPlaying) {
      this.startTestSound();
    } else {
      this.stopTestSound();
    }
  }

  private startTestSound(): void {
    from(this.testSound.play())
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.isPlaying = true;
          fromEvent(this.testSound, 'ended')
            .pipe(take(1))
            .subscribe(() => {
              this.isPlaying = false;
            });
        },
        error: () => {
          this.isPlaying = false;
        },
      });
  }

  private stopTestSound(): void {
    this.testSound.pause();
    this.testSound.currentTime = 0; // Reset playback position
    this.isPlaying = false;
  }

  private changeAudioOutput() {
    const testElement = this.testSound as SinkedHTMLMediaElement;
    if (
      testElement &&
      this.deviceService.checkIfOutputSelectionIsPossible(testElement)
    ) {
      const currentAudioOutput =
        this.deviceService.getSelectedAudioOutputDevice();
      if (currentAudioOutput) testElement.setSinkId(currentAudioOutput).then();
    }
  }
}
