import { BillingAddressDto } from 'projects/helper-client/src/api/gen';

export class BillingAddress {
  firstAddressLine?: string | null;
  secondAddressLine?: string | null;
  locality?: string | null;
  postCode?: string | null;
  region?: string | null;
  country?: string | null;

  static fromDto(dto: BillingAddressDto): BillingAddress {
    return {
      firstAddressLine: dto.firstAddressLine,
      secondAddressLine: dto.secondAddressLine,
      locality: dto.locality,
      postCode: dto.postCode,
      region: dto.region,
      country: dto.country,
    } as BillingAddress;
  }
}
