import { SubscriptionEndTimeDto } from '../../../api/gen';

export class SubscriptionEndTime {
  subscriptionIdentifier?: string;
  endTime?: string;

  static fromDto(dto: SubscriptionEndTimeDto): SubscriptionEndTime {
    return {
      subscriptionIdentifier: dto.subscriptionIdentifier,
      endTime: dto.endTime,
    } as SubscriptionEndTime;
  }
}
