import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, skip, Subject } from 'rxjs';
import { Mode } from '../../annotations/drawing-canvas/drawing-canvas.component';
import { CapabilitiesService } from './capabilities.service';
import { CamState, DeviceService, MicState } from './device.service';
import {
  DataTrackObject,
  DataTrackObjectType,
  DataTrackService,
} from './track-ctrl/data-track.service';

@Injectable({
  providedIn: 'root',
})
export class CallCtrlService {
  public toggleFlash$ = new Subject<void>();
  public changeZoom$ = new Subject<ZoomState>();

  // TODO: This needs to be refactored. Maybe video content service? or a new one
  modeChannel$: BehaviorSubject<Mode> = new BehaviorSubject<Mode>(Mode.Idle);
  private readonly micState$: BehaviorSubject<MicState>;
  private readonly camState$: BehaviorSubject<CamState>;
  private readonly flashState$ = new BehaviorSubject<FlashState>(
    FlashState.NotAvailableForCam,
  );
  private readonly zoomState$: BehaviorSubject<ZoomState> =
    new BehaviorSubject<ZoomState>(ZoomState.Default);

  constructor(
    private readonly capabilitiesService: CapabilitiesService,
    private readonly dataTrackService: DataTrackService,
    readonly deviceService: DeviceService,
  ) {
    let micState = deviceService.getMicState();
    micState ??= MicState.Unmuted;
    this.micState$ = new BehaviorSubject<MicState>(micState);

    let camState = deviceService.getCamState();
    camState ??= CamState.On;
    this.camState$ = new BehaviorSubject<CamState>(camState);

    this.camState$.pipe(skip(1)).subscribe((x) => {
      deviceService.setCamState(x);
    });
    this.micState$.pipe(skip(1)).subscribe((x) => {
      deviceService.setMicState(x);
    });
    this.capabilitiesService.capabilitiesChanged$.subscribe((capabilities) => {
      this.flashState$.next(capabilities.flash);
      this.zoomState$.next(capabilities.zoom);
    });

    // Those were in a different service before (local-track-ctrl), making
    // the subject a bit redundant now. However, we keep it.
    this.toggleFlash$.subscribe(() => {
      this.dataTrackService.sendDataObject(
        DataTrackObject.withType(DataTrackObjectType.TOGGLE_FLASH).withData(
          null,
        ),
      );
    });
    this.changeZoom$.subscribe((newState) => {
      this.dataTrackService.sendDataObject(
        DataTrackObject.withType(DataTrackObjectType.CHANGE_ZOOM).withData(
          newState,
        ),
      );
    });
  }

  public toggleFlash(): void {
    this.toggleFlash$.next();
  }

  public changeZoom(zoomState: ZoomState): void {
    this.changeZoom$.next(zoomState);
  }

  public async toggleCamState(): Promise<void> {
    const current = this.camState$.getValue();
    this.camState$.next(current === CamState.Off ? CamState.On : CamState.Off);
  }

  public async toggleMicState(): Promise<void> {
    const current = this.micState$.getValue();
    this.micState$.next(
      current === MicState.Muted ? MicState.Unmuted : MicState.Muted,
    );
  }

  public enableCamera(): void {
    this.camState$.next(CamState.On);
  }

  public enableMicrophone(): void {
    this.micState$.next(MicState.Unmuted);
  }

  subscribeToCamState(): Observable<CamState> {
    return this.camState$.asObservable();
  }

  subscribeToMicState(): Observable<MicState> {
    return this.micState$.asObservable();
  }

  subscribeToFlashState(): Observable<FlashState> {
    return this.flashState$.asObservable();
  }

  subscribeToZoomState(): Observable<ZoomState> {
    return this.zoomState$.asObservable();
  }
}

export enum FlashState {
  NotSupported,
  NotAvailableForCam,
  Off,
  On,
}

export enum ZoomState {
  Default = 0,
  Zoom1 = 1,
}
