import { InquiryLocationDto } from '../../../api/gen';
import { LatLng } from './lat-lng';

export class InquiryLocation {
  coordinates?: LatLng;

  static fromDto(dto: InquiryLocationDto): InquiryLocation {
    return {
      coordinates: dto.coordinates ? LatLng.fromDto(dto.coordinates) : null,
    } as InquiryLocation;
  }
}
