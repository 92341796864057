import { Component, OnInit, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, filter, map, mergeMap, take } from 'rxjs';
import {
  EditBasicInquiryInfoDialogComponent,
  EditInquiryBaseDataFields,
} from '../../shared/dialogs/edit-basic-inquiry-info-dialog/edit-basic-inquiry-info-dialog.component';
import { VideoChatService } from '../services/videochat.service';
import { Inquiry } from '../../model/inquiry/inquiry';
import { InquiryService } from '../../services/api/inquiry.service';
import { RequestLocationDialogComponent } from '../dialogs/request-location-dialog/request-location-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocationResult } from '../services/location/location-result';
import { InquiryLocationService } from '../../services/api/inquiry-location.service';
import { ViewLocationDialogComponent } from '../dialogs/view-location-dialog/view-location-dialog.component';

@Component({
  selector: 'app-inquiry-infos',
  templateUrl: './inquiry-infos.component.html',
  styleUrls: ['./inquiry-infos.component.scss'],
  providers: [InquiryService, InquiryLocationService],
})
export class InquiryInfosComponent implements OnInit {
  protected inquiry$ = new BehaviorSubject<Inquiry>(null);
  protected isLoading$ = signal(true);

  nameLbl = $localize`Name`;
  nameValue = signal<string>(null);
  emailLbl = $localize`Email`;
  locationLbl = $localize`Location`;
  location = signal<string>(null);

  constructor(
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly videoChatService: VideoChatService,
    private readonly inquiryService: InquiryService,
    private readonly inquiryLocationService: InquiryLocationService,
  ) {}

  ngOnInit(): void {
    this.refresh();
    this.inquiry$.asObservable().subscribe((inquiry) => {
      if (inquiry) {
        this.updateName(inquiry);
        this.updateLocation(inquiry);
      }
    });
  }

  private updateName(inquiry: Inquiry) {
    if (inquiry.firstName === null && inquiry.lastName === null) {
      this.nameValue.set(null);
    } else if (inquiry.lastName === null) {
      this.nameValue.set(inquiry.firstName);
    } else if (inquiry.firstName === null) {
      this.nameValue.set(inquiry.lastName);
    } else {
      this.nameValue.set(`${inquiry.firstName} ${inquiry.lastName}`);
    }
  }

  private updateLocation(inquiry: Inquiry) {
    if (inquiry.locations === null || inquiry.locations.length === 0) {
      this.location.set(null);
    } else {
      const locationDisplayValue = `${inquiry.locations[0].coordinates.latitude} / ${inquiry.locations[0].coordinates.longitude}`;
      this.location.set(locationDisplayValue);
    }
  }

  private refresh(): void {
    this.isLoading$.set(true);
    this.videoChatService.activeRoom$
      .pipe(
        filter((x) => !!x?.data?.inquiryIdentifier),
        map((x) => x.data.inquiryIdentifier),
        take(1),
        mergeMap((x) => this.inquiryService.getInquiry(x)),
      )
      .subscribe((x) => {
        this.inquiry$.next(x);
        this.isLoading$.set(false);
      });
  }

  protected editContactDetails() {
    const inquiry = this.inquiry$.getValue();
    this.dialog
      .open(EditBasicInquiryInfoDialogComponent, {
        data: {
          inquiryId: inquiry.inquiryIdentifier,
          phoneNumber: inquiry.phoneNumber,
          email: inquiry.email,
          firstName: inquiry.firstName,
          lastName: inquiry.lastName,
        } as EditInquiryBaseDataFields,
        maxWidth: '100vw',
        maxHeight: '100vh',
      })
      .afterClosed()
      .subscribe((shouldReload) => {
        if (shouldReload) {
          this.refresh();
        }
      });
  }

  requestLocation() {
    const inquiryId = this.inquiry$.getValue().inquiryIdentifier;
    this.dialog
      .open(RequestLocationDialogComponent, {
        width: '30rem',
        disableClose: true,
      })
      .afterClosed()
      .subscribe((res?: LocationResult) => {
        if (res) {
          this.inquiryLocationService
            .createLocation(
              inquiryId,
              res.location.coords.latitude,
              res.location.coords.longitude,
            )
            .subscribe(() => {
              this.refresh();
            });
        } else {
          this.snackBar.open(
            $localize`Konnte Standort nicht abfragen.`,
            $localize`Ok`,
            { duration: 3000 },
          );
        }
      });
  }

  viewLocation() {
    const locations = this.inquiry$.getValue().locations;
    if (locations.length > 0) {
      this.dialog.open(ViewLocationDialogComponent, {
        width: '60rem',
        height: '40rem',
        data: {
          latitude: locations[0].coordinates.latitude,
          longitude: locations[0].coordinates.longitude,
        },
      });
    }
  }
}
