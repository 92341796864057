import { Injectable } from '@angular/core';
import { AudioAnalyzer } from '../diagnostics/audio-analyzer';
import { filter, Subject } from 'rxjs';
import { MediaTrackService } from './track-ctrl/media-track.service';

@Injectable({
  providedIn: 'root',
})
export class AudioLevelService {
  private localAudioMonitor: AudioAnalyzer;
  private remoteAudioMonitor: AudioAnalyzer;

  private readonly localAudioVolume = new Subject<number>();
  private readonly remoteAudioVolume = new Subject<number>();

  localAudioVolume$ = this.localAudioVolume.asObservable();
  removeAudioVolume$ = this.remoteAudioVolume.asObservable();

  constructor(readonly localTracks: MediaTrackService) {
    localTracks.audioTrackUpdated$
      .pipe(filter((x) => !!x?.getMediaStreamTrack()))
      .subscribe((x) => {
        this.monitorLocalAudio(new MediaStream([x.getMediaStreamTrack()]));
      });
  }

  monitorLocalAudio(stream: MediaStream): void {
    if (this.localAudioMonitor) {
      this.localAudioMonitor.stop();
    }
    this.localAudioMonitor = new AudioAnalyzer(stream);
    this.localAudioMonitor.getObservable().subscribe((x) => {
      this.localAudioVolume.next(x);
    });
  }

  monitorRemoteAudio(stream: MediaStream): void {
    if (this.remoteAudioMonitor) {
      this.remoteAudioMonitor.stop();
    }
    this.remoteAudioMonitor = new AudioAnalyzer(stream);
    this.remoteAudioMonitor.getObservable().subscribe((x) => {
      this.remoteAudioVolume.next(x);
    });
  }
}
