import {
  AttachmentCvService as Api,
  TranslateFoundText,
} from '../../../api/gen';
import { map, Observable } from 'rxjs';
import { FoundText } from '../../model/attachment/found-text';
import { Injectable } from '@angular/core';

@Injectable()
export class AttachmentCvService {
  constructor(private readonly api: Api) {}

  public readTextFromAttachment(attachmentId: string): Observable<FoundText[]> {
    return this.api
      .attachmentsAttachmentIdCvPost(attachmentId)
      .pipe(map((x) => x.map((dto) => FoundText.fromDto(dto))));
  }

  public translateFoundText(
    attachmentId: string,
    foundTextId: string,
    targetLocale: string,
  ): Observable<FoundText> {
    const request = {
      attachmentId: attachmentId,
      foundTextId: foundTextId,
      targetLocale: targetLocale,
    } as TranslateFoundText;
    return this.api
      .attachmentsAttachmentIdCvFoundTextIdTranslatePost(
        attachmentId,
        foundTextId,
        request,
      )
      .pipe(map((x) => FoundText.fromDto(x)));
  }
}
