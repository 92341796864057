import {
  CreateInquiryLocation,
  InquiryLocationService as Api,
} from '../../../api/gen';
import { InquiryLocation } from '../../model/inquiry/inquiry-location';
import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class InquiryLocationService {
  constructor(private readonly inquiryLocationService: Api) {}

  createLocation(
    inquiryId: string,
    latitude: number,
    longitude: number,
  ): Observable<InquiryLocation> {
    const request = {
      inquiryId: inquiryId,
      latitude: latitude,
      longitude: longitude,
    } as CreateInquiryLocation;
    return this.inquiryLocationService
      .contactInquiryInquiryIdLocationPost(inquiryId, request)
      .pipe(map((dto) => InquiryLocation.fromDto(dto)));
  }
}
