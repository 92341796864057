import { FoundTextDto } from '../../../api/gen';
import { FoundTextTranslation } from './found-text-translation';

export class FoundText {
  id?: string;
  text?: string | null;
  translations?: FoundTextTranslation[] | null;

  public static fromDto(dto: FoundTextDto): FoundText {
    return {
      id: dto.id,
      text: dto.text,
      translations:
        dto.translations != null
          ? dto.translations.map((tr) => FoundTextTranslation.fromDto(tr))
          : null,
    } as FoundText;
  }
}
