import { Component, OnDestroy } from '@angular/core';
import { CallCtrlService } from '../../services/call-ctrl.service';
import { takeUntil } from 'rxjs/operators';
import { map, Observable, Subject } from 'rxjs';
import { DiagnosticsService } from '../../services/diagnostics/diagnostics.service';

@Component({
  selector: 'app-mic-settings-preview',
  templateUrl: './mic-settings-preview.component.html',
  styleUrl: './mic-settings-preview.component.scss',
})
export class MicSettingsPreviewComponent implements OnDestroy {
  protected isMuted$: Observable<boolean>;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly callCtrlService: CallCtrlService,
    private readonly diagnosticsService: DiagnosticsService,
  ) {
    this.isMuted$ = this.diagnosticsService.diagnosticsChanged$.pipe(
      takeUntil(this.unsubscribe$),
      map((x) => !x.localMicrophoneTurnedOn),
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  turnOnMic(): void {
    this.callCtrlService.toggleMicState().then();
  }
}
